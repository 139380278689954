<div [ngClass]="claseContenedor()">
  <div class="vScroll">
    <div class="row">
        <div class="col-lg-11"></div>
    
        <button (click)="cancelar();" type="button" class="btn col-lg-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
              </svg>
        </button>
    </div>

      <form autocomplete="off" [formGroup]="formulario" (ngSubmit)="grabar()">
  
              <div class="row mb-2">
                  <div class="col-1"></div>
                  <div class="col-lg-9">
                      <h2 [ngClass]="claseH2()">Nuevo Cliente</h2>
                  </div>
                  <div class="col-lg-1">
                    <div class="btn-group-lg me-2" role="group" aria-label="Second group">
                        <button type="submit" class="btn btn-primary">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-save-fill" viewBox="0 0 16 16">
                                    <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z"/>
                                </svg> 
                            </div>
                            Grabar
                        </button>

                    </div>

                  </div>
              </div>
  
  
  
              <div class="form-group row mt-5">
                  <label class="col-lg-2 col-md-4 col-form-label text-end">Cliente</label>
                  <div class="col-lg-2 col-md-5">
                      <input mTab (focusout)="validarCliente();" class="form-control" id=codigo type="text" placeholder="" formControlName="codigo" #codigo>
                      <small *ngIf="campoNoValido('codigo')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
                  </div>
                  <div class="col-lg-1">
                    <button *ngIf="verOcr" (click)="activarOcr = true;" type="button" class="btn btn-secondary col-lg-12">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" fill="currentColor" class="bi bi-phone-landscape" viewBox="0 0 16 16">
                            <path d="M1 4.5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-6zm-1 6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v6z"/>
                            <path d="M14 7.5a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"/>
                          </svg>
                    </button>
                    <button *ngIf="verFichasColor" (click)="irFichaColor();" type="button" class="btn btn-secondary col-lg-12" title="Ficha de Color">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" fill="currentColor" class="bi bi-person-video2" viewBox="0 0 16 16">
                            <path d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                            <path d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2ZM1 3a1 1 0 0 1 1-1h2v2H1V3Zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3H5Zm-4-2h3v2H2a1 1 0 0 1-1-1v-1Zm3-1H1V8h3v2Zm0-3H1V5h3v2Z"/>
                        </svg>
                    </button>
                </div>
                  <label *ngIf="!campoVisible('tipoDocumento')" class="col-lg-2 col-md-2 col-form-label text-end">{{texto('cif')}}</label>
                  <div  *ngIf="campoVisible('tipoDocumento')"class="dropdown col-lg-2 col-md-2 text-end">
                      <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="tipodDocumento" data-bs-toggle="dropdown" aria-expanded="false">
                          {{tipoDocumentoActivo.descripcion}}
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <li (click)="tipoDocumentoActivo=tipoDocumento" *ngFor="let tipoDocumento of tiposDocumento"><a class="dropdown-item">{{tipoDocumento.descripcion}}</a></li>
                      </ul>
                  </div>
                  <div class="col-lg-3 col-md-4">
                      <input mTab class="form-control" id=cif type="text" placeholder="" formControlName="cif">
                      <!-- <small *ngIf="!validarDni()" class="form-text text-danger">Dni no válido</small> -->
                      <small *ngIf="!validarDni() && campoVisible('tipoDocumento')" class="form-text text-info">No es formato DNI</small>


                  </div>
              </div>
  
              <div class="form-group row mt-1">
                  <div class="col-lg-4 col-md-9"></div>
                  <div class="col-lg-1">
                  </div>
                      <label  *ngIf="campoVisible('fechaExpedicion')" class="col-lg-2 col-md-2 col-form-label text-end">Fecha Expedición</label>
                      <div *ngIf="campoVisible('fechaExpedicion')" class="col-lg-3 col-md-4">
                          <input mTab class="form-control" id=fechaExpedicion type="date" placeholder="" formControlName="fechaExpedicion">
                      </div>
              </div>
              <hr>
  
              <!-- ----------------------------------------------------------------------- -->
              <!--                               FILA NOMBRE                               -->
              <!-- ----------------------------------------------------------------------- -->
  
              <div class="form-group row mt-1">
                  <label *ngIf="campoVisible('nombre')" class="col-lg-2 col-md-4 col-form-label text-end">Nombre</label>
                  <div *ngIf="campoVisible('nombre')" class="col-lg-3 col-md-6">
                      <input mTab class="form-control" id=nombre type="text" placeholder="" formControlName="nombre">
                  </div>
                  <label *ngIf="campoVisible('apellido1')"  class="col-lg-2 col-md-4 col-form-label text-end">Apellido</label>
                  <div *ngIf="campoVisible('apellido1')" class="col-lg-3 col-md-6">
                      <input mTab class="form-control" id=apellido1 type="text" placeholder="" formControlName="apellido1">
                  </div>
              </div>
  
              <div class="form-group row mt-1">
                  <label *ngIf="campoVisible('apellido2')"  class="col-lg-2 col-md-4 col-form-label text-end">2º Apellido</label>
                  <div *ngIf="campoVisible('apellido2')" class="col-lg-3 col-md-6">
                      <input mTab (focusout)="completarNombreFiscal()" class="form-control" id=apellido2 type="text" placeholder="" formControlName="apellido2">
                  </div>
  
                  <label *ngIf="campoVisible('sexo')" class="col-lg-2 col-md-2 col-form-label text-end">Sexo</label>
                  <div *ngIf="campoVisible('sexo')" class="dropdown col-lg-3 col-md-4">
                      <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="sexo" data-bs-toggle="dropdown" aria-expanded="false">
                          {{sexoActivo}}
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <li (click)="sexoActivo=sexo!" *ngFor="let sexo of sexos"><a class="dropdown-item">{{sexo}}</a></li>
                      </ul>
                  </div>
              </div>
  
              <div *ngIf="campoVisible('fechaNacimiento')" class="form-group row mt-1">
                  <div class="col-lg-5 col-md-5">
                  </div>
                  <label class="col-lg-2 col-md-2 col-form-label text-end">Fecha Nacimiento</label>
                  <div class="col-lg-3 col-md-6">
                      <input mTab class="form-control" id=contacto type="date" placeholder="" formControlName="fechaNacimiento">
                  </div>
              </div>
  
              <hr *ngIf="campoVisible('nombre')">
  
              <div class="form-group row mt-1">
                  <label *ngIf="campoVisible('nombreFiscal')" class="col-lg-2 col-md-4 col-form-label text-end">Nombre Fiscal</label>
                  <div *ngIf="campoVisible('nombreFiscal')" class="col-lg-3 col-md-6">
                      <input mTab class="form-control" id=nombreFiscal type="text" placeholder="" formControlName="nombreFiscal">
                      <small *ngIf="campoNoValido('nombreFiscal')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
                  </div>
                  <label *ngIf="campoVisible('nombreComercial')" class="col-lg-2 col-md-4 col-form-label text-end">Nombre Comercial</label>
                  <div *ngIf="campoVisible('nombreComercial')" class="col-lg-3 col-md-6">
                      <input mTab class="form-control" id=nombreComercial type="text" placeholder="" formControlName="nombreComercial">
                      <small *ngIf="campoNoValido('nombreComercial')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
                  </div>
              </div>
  
              <div class="form-group row mt-1">
                  <label class="col-lg-2 col-md-4 col-form-label text-end">Tlf. Principal</label>
                  <div class="col-lg-3 col-md-6">
                      <input mTab class="form-control" id=tlfPrincipal type="text" placeholder="" formControlName="tlfPrincipal">
                      <small *ngIf="campoNoValido('tlfPrincipal')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
                  </div>
                  <label class="col-lg-2 col-md-4 col-form-label text-end">Email Principal</label>
                  <div class="col-lg-3 col-md-6">
                      <input mTab class="form-control" id=emailPrincipal type="text" placeholder="" formControlName="emailPrincipal">
                      <small *ngIf="campoNoValido('emailPrincipal')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
                  </div>
              </div>
  
              <div class="form-group row mt-1">
                  <div class="col-lg-5 col-md-5">
                  </div>
                  <label class="col-lg-2 col-md-2 col-form-label text-end">Contacto</label>
                  <div class="col-lg-3 col-md-6">
                      <input mTab class="form-control" id=contacto type="text" placeholder="" formControlName="contacto">
                  </div>
              </div>
  
          <hr>
  
          <!-- ----------------------------------------------------------------------- -->
          <!--                             FILA DIRECCIÓN                              -->
          <!-- ----------------------------------------------------------------------- -->
  
          <div class="form-group row mt-4">
             
              <label class="col-lg-2 col-md-2 col-form-label text-end">País</label>
              <div class="col-lg-3 col-md-4">
                  <input mTab class="form-control" id=pais type="text" placeholder="" formControlName="pais">
                  <small *ngIf="campoNoValido('pais')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
              </div>
  
          </div>
  
  
          <div class="form-group row mt-4">
              <label class="col-lg-2 col-md-2 col-form-label text-end">Dirección</label>
              <div class="col-lg-3 col-md-10">
                  <input mTab class="form-control" id=direccion type="text" placeholder="" formControlName="direccion">
                  <small *ngIf="campoNoValido('direccion')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
              </div>
              <label class="col-lg-2 col-md-2 col-form-label text-end">C.P.</label>
              <div class="col-lg-3 col-md-4">
                  <input mTab (focusout)="validarCP();" class="form-control" id=codigoPostal type="text" placeholder="" formControlName="codigoPostal">
                  <small *ngIf="campoNoValido('codigoPostal')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
              </div>
          </div>
  
  
          <div class="form-group row mt-4">
              <label class="col-lg-2 col-md-2 col-form-label text-end">Localidad</label>
              <div class="col-lg-3 col-md-10">
                  <input mTab class="form-control" id=localidad type="text" placeholder="" formControlName="localidad">
                  <small *ngIf="campoNoValido('localidad')" class="form-text text-danger">Debe contener un mínimo de 4 caracteres</small>
              </div>
              <label *ngIf="esEspana" class="col-lg-2 col-md-2 col-form-label text-end">Provincia</label>
  
              <div *ngIf="esEspana" class="dropdown col-lg-3 col-md-4">
                  <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="provincia" data-bs-toggle="dropdown" aria-expanded="false">
                      {{nombreProvincia}}
                  </a>
  
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <li (click)="provinciaActiva=provincia.codigo!" *ngFor="let provincia of provincias"><a class="dropdown-item">{{provincia.nombre}}</a></li>
                  </ul>
              </div>
          </div>
  
         
          
  
          <!-- ----------------------------------------------------------------------- -->
          <!--                           FILA OBSERVACIONES                            -->
          <!-- ----------------------------------------------------------------------- -->
  
          <div class="form-group row mt-4">
              <div class="col-lg-1"></div>
              <div class="mb-3 col-lg-9">
                  <label for="exampleFormControlTextarea1" class="form-label">Observaciones</label>
                  <textarea class="form-control" id="observaciones" rows="3" formControlName="observaciones"></textarea>
              </div>
          </div>
  
  
  
          <!-- ----------------------------------------------------------------------- -->
          <!--                           MENSAJES                                      -->
          <!-- ----------------------------------------------------------------------- -->
  
          <div mt-2>
  
  
  
  
  
              <!-- ----------------------------------------------------------------------- -->
              <!--                            BOTONES (INICIO)                             -->
              <!-- ----------------------------------------------------------------------- -->
  
              <!-- <div class="row mb-2">
                  <div class="col-lg-7">
  
                  </div>
                  <div class="btn-toolbar col-lg-4" role="toolbar" aria-label="Toolbar with button groups">
  
  
  
                      <div class="btn-group-lg me-2" role="group" aria-label="Second group">
                          <button type="submit" class="btn btn-primary">
                              <div>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-save-fill" viewBox="0 0 16 16">
                                      <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v7.793L4.854 6.646a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l3.5-3.5a.5.5 0 0 0-.708-.708L8.5 9.293V1.5z"/>
                                  </svg> 
                              </div>
                              Grabar
                          </button>
  
                      </div>
  
                  </div>
              </div> -->
  
              <!-- ----------------------------------------------------------------------- -->
              <!--                              BOTNOES(FIN)                               -->
              <!-- ----------------------------------------------------------------------- -->
  
  
          </div>
  
      </form>
    </div>
  </div>
  
  <app-spinner *ngIf="activarSpinner"></app-spinner>
  <app-mensaje class="traerAlFrente" [mensaje]=mensaje (botonesClick)="controlBotones($event)"></app-mensaje>
  
  <app-buscador *ngIf="activarBuscador" (onCancel)="activarBuscador=false;"></app-buscador>
  <app-ocr *ngIf="activarOcr" (onDni)="formatearDni($event)" (onCancel)="activarOcr = false;"></app-ocr>
  
  
